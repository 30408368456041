import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { EAuctionBelowMinAskBehaviour, ESellerBusinessType, IBuyerAuctionView, Validation } from '@caronsale/cos-models';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { BiddingService } from '@cosBuyer/partials/services/bidding/bidding.service';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { enumToArray } from '@cosCoreUtils/type-script-utils';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, filter, map } from 'rxjs';

export enum PriceTargetInfoState {
  NONE = 'NONE',
  IS_PRIVATE = 'IS_PRIVATE',
  RUNNING_REACHED = 'RUNNING_REACHED',
  RUNNING_NOT_REACHED_SHOW_MIN_ASK = 'RUNNING_NOT_REACHED_SHOW_MIN_ASK',
  RUNNING_NOT_REACHED = 'RUNNING_NOT_REACHED',
  RUNNING_ALMOST_REACHED_ONE_BID_LEFT = 'RUNNING_ALMOST_REACHED_ONE_BID_LEFT',
  RUNNING_ALMOST_REACHED = 'RUNNING_ALMOST_REACHED',
  CLOSED_WON = 'CLOSED_WON',
  CLOSED_WON_BELOW_MIN_ASK = 'CLOSED_WON_BELOW_MIN_ASK',
  CLOSED_LOST = 'CLOSED_LOST',
}

type Vm = {
  auction: IBuyerAuctionView;
  state: PriceTargetInfoState;
  remainingBids?: number;
  currentHighestBidValue?: number;
};

@Component({
  selector: 'app-price-target-info',
  templateUrl: './price-target-info.component.html',
  standalone: true,
  imports: [
    //
    CommonModule,
    EnzoComponentsModule,
    CosPopoverModule,
    TranslateModule,
    GeneralPipesModule,
  ],
})
export class PriceTargetInfoComponent {
  private auctionSubject: BehaviorSubject<IBuyerAuctionView> = new BehaviorSubject<IBuyerAuctionView>(null);
  private visibleStatesSubject: BehaviorSubject<PriceTargetInfoState[]> = new BehaviorSubject<PriceTargetInfoState[]>(enumToArray(PriceTargetInfoState));

  @Input()
  public set auction(auction: IBuyerAuctionView) {
    this.auctionSubject.next(auction);
  }

  @Input()
  public set visibleStates(visibleStates: PriceTargetInfoState[]) {
    this.visibleStatesSubject.next(visibleStates);
  }

  public PriceTargetInfoState: typeof PriceTargetInfoState = PriceTargetInfoState;

  public vm$: Observable<Vm> = combineLatest({
    auction: this.auctionSubject,
    visibleStates: this.visibleStatesSubject,
  }).pipe(
    filter(({ auction }) => !!auction),
    map(({ auction, visibleStates }) => {
      const state = this.calculatePriceTargetInfoState(auction, visibleStates);
      const remainingBids = state === PriceTargetInfoState.RUNNING_ALMOST_REACHED ? this.biddingService.getRemainingBids(auction) : undefined;
      const currentHighestBidValue = state === PriceTargetInfoState.CLOSED_WON ? auction.currentHighestBidValue : undefined;

      return {
        auction,
        state,
        remainingBids,
        currentHighestBidValue,
      };
    }),
  );

  public constructor(private biddingService: BiddingService) {}

  private calculatePriceTargetInfoState(auction: IBuyerAuctionView, visibleStates: PriceTargetInfoState[]): PriceTargetInfoState {
    if (
      Validation.isAuctionRunning(auction) &&
      auction.displayMinAsk &&
      auction.sellerType === ESellerBusinessType.PRIVATE &&
      visibleStates.includes(PriceTargetInfoState.IS_PRIVATE)
    ) {
      return PriceTargetInfoState.IS_PRIVATE;
    }

    if (Validation.isAuctionRunning(auction) && auction.isTest) {
      return PriceTargetInfoState.NONE;
    }

    if (Validation.isAuctionRunning(auction) && auction.isMinAskReached && visibleStates.includes(PriceTargetInfoState.RUNNING_REACHED)) {
      return PriceTargetInfoState.RUNNING_REACHED;
    }

    if (Validation.isAuctionRunning(auction) && auction.displayMinAsk && visibleStates.includes(PriceTargetInfoState.RUNNING_NOT_REACHED_SHOW_MIN_ASK)) {
      return PriceTargetInfoState.RUNNING_NOT_REACHED_SHOW_MIN_ASK;
    }

    if (
      Validation.isAuctionRunning(auction) &&
      this.biddingService.shouldDisplayRemainingBids(auction) &&
      this.biddingService.getRemainingBids(auction) === 1 &&
      visibleStates.includes(PriceTargetInfoState.RUNNING_ALMOST_REACHED_ONE_BID_LEFT)
    ) {
      return PriceTargetInfoState.RUNNING_ALMOST_REACHED_ONE_BID_LEFT;
    }

    if (
      Validation.isAuctionRunning(auction) &&
      this.biddingService.shouldDisplayRemainingBids(auction) &&
      visibleStates.includes(PriceTargetInfoState.RUNNING_ALMOST_REACHED)
    ) {
      return PriceTargetInfoState.RUNNING_ALMOST_REACHED;
    }

    if (Validation.isAuctionRunning(auction) && visibleStates.includes(PriceTargetInfoState.RUNNING_NOT_REACHED)) {
      return PriceTargetInfoState.RUNNING_NOT_REACHED;
    }

    if (
      Validation.isAuctionClosedOrFinished(auction) &&
      auction.amIHighestBidder &&
      Validation.isAuctionClosedSuccessfully(auction) &&
      visibleStates.includes(PriceTargetInfoState.CLOSED_WON)
    ) {
      return PriceTargetInfoState.CLOSED_WON;
    }

    if (
      Validation.isAuctionClosedOrFinished(auction) &&
      auction.amIHighestBidder &&
      Validation.isAuctionClosedWithBidBelowMinimumAsk(auction) &&
      visibleStates.includes(PriceTargetInfoState.CLOSED_WON_BELOW_MIN_ASK) &&
      auction.belowMinAskBehaviour === EAuctionBelowMinAskBehaviour.DISABLE_CLONE_AND_START
    ) {
      return PriceTargetInfoState.CLOSED_LOST;
    }

    if (
      Validation.isAuctionClosedOrFinished(auction) &&
      auction.amIHighestBidder &&
      Validation.isAuctionClosedWithBidBelowMinimumAsk(auction) &&
      visibleStates.includes(PriceTargetInfoState.CLOSED_WON_BELOW_MIN_ASK)
    ) {
      return PriceTargetInfoState.CLOSED_WON_BELOW_MIN_ASK;
    }

    if (visibleStates.includes(PriceTargetInfoState.CLOSED_LOST)) {
      return PriceTargetInfoState.CLOSED_LOST;
    }

    return PriceTargetInfoState.NONE;
  }
}
