@if (discountItem(); as discountItem) {
  <app-tag-with-icon
    testid="icon-tag"
    backgroundColor="purple"
    color="purple-dark"
    icon="no-fees"
    [label]="'auction.fees-discount.' + discountItem.typeLabel | translate: { amount: discountItem.percentage }"
    popoverWidth="25%"
    [popoverTemplate]="feesDiscountPopoverTemplate" />
}

<ng-template #feesDiscountPopoverTemplate>
  <enzo-text kind="label"> {{ 'auction.fees-discount.tooltip' | translate }} </enzo-text>
</ng-template>
