@if (videoUrls()?.[selectedIndex()]) {
  <div class="selected-video">
    <video [src]="videoUrls()?.[selectedIndex()]" controls [autoplay]="setAutoPlay()" testid="vehicle-video"></video>
    @if (videoUrls().length > 1) {
      <enzo-icon color="white" icon="arrow-left" class="arrows" (click)="decreaseSelectedIndex()"></enzo-icon>
      <enzo-icon color="white" icon="arrow-right" class="arrows" (click)="increaseSelectedIndex()"></enzo-icon>
      <enzo-text class="count-overlay">{{ selectedIndex() + 1 }}/{{ videoUrls().length }}</enzo-text>
    }
  </div>
}
