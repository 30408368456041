<ng-container *ngIf="!(auction.paymentProcess | isCosPay)">
  <enzo-button
    *ngIf="!auction.isPaidByBuyer"
    stretch
    kind="secondary"
    variant="highlight"
    icon="invoice"
    iconPosition="leading"
    [label]="'payment.confirm-payment' | translate"
    [loading]="isMarkingAsPaid"
    [disabled]="isVehicleReauctioned()"
    (enzoClick)="handleShowPaymentInfo($event)">
  </enzo-button>

  <enzo-button *ngIf="auction.isPaidByBuyer" stretch kind="secondary" [label]="'payment.marked-as-paid' | translate" disabled></enzo-button>
</ng-container>

<ng-container *ngIf="auction.paymentProcess | isCosPay">
  <enzo-button
    stretch
    kind="secondary"
    icon="invoice"
    iconPosition="leading"
    [label]="'payment.payment-information' | translate"
    [disabled]="isVehicleReauctioned()"
    (enzoClick)="handleCosPayClick($event)">
  </enzo-button>
</ng-container>

<app-additional-tax-info [auctionBiddingData]="auction"></app-additional-tax-info>
