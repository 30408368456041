import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { catchError, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { IAccount, IAuction, IAuctionBid, IBuyerAuctionView, IBuyerUser, IPrebookedService } from '@caronsale/cos-models';
import { CurrencyEuroPipe } from '@caronsale/frontend-pipes';

import { VoucherService } from '@cosBuyer/partials/services/voucher.service';
import {
  BiddingConfirmationDialogComponent,
  IBiddingConfirmationData,
  IBiddingConfirmationResult,
} from '@cosBuyer/partials/services/bidding/bidding-confirmation-dialog/bidding-confirmation-dialog.component';
import { BuyerSelfRegisterModalComponent } from '@cosBuyer/partials/buyer-self-register-modal/buyer-self-register-modal.component';
import { MobileBidDialogComponent } from '@cosBuyer/partials/auction-detail-view/bid-info-footer/mobile-bid-dialog/mobile-bid-dialog.component';
import { I18nInfoDialogComponent } from '@cosCoreComponentsGeneral/i18n/info-dialog/i18n-info-dialog.component';
import { I18nErrorDialogComponent } from '@cosCoreComponentsGeneral/i18n/error-dialog/i18n-error-dialog.component';

import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';

import { AccountDataService } from '@cosCoreServices/account-data/account-data.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { PrebookedServicesService } from '@cosCoreServices/prebooked-services/prebooked-services.service';
import { DialogRefOrMatDialogRef, EDialogId, EnzoDialogService } from '@cos/components/modal-dialogs/enzo-dialog.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { BidProhibitedProperties } from '@cosCoreServices/product-analytics/amplitude/ampli';
import { minimalBidIncrement, remainingBids } from '@cos/config/AuctionConstants';

export const NONE = 'None';

const AUCTION_IS_EXPENSIVE_THRESHOLD = 3700;
const BID_INCREMENT_WITH_GAP_DIVIDER = 3;
const BID_INCREMENT_WITH_GAP_ROUNDING_THRESHOLD = 1000;
const BID_INCREMENT_WITH_GAP_BELOW_ROUNDING_THRESHOLD = 50;
const BID_INCREMENT_WITH_GAP_ABOVE_ROUNDING_THRESHOLD = 100;
const BID_INCREMENT_WITH_GAP_MAX = 3000;

@Injectable({
  providedIn: 'root',
})
export class BiddingService {
  public isBiddingDialogOpened: boolean = false;

  private lastTrackedBidProhibitedEvent: { auctionUuid: string; bidValue: number; bidSource: BidProhibitedProperties['Bid source'] } = null;

  public constructor(
    private accountDataSvc: AccountDataService,
    private buyerAuctionService: BuyerAuctionService,
    private cosBuyerClientService: CosBuyerClientService,
    private i18nSnackService: I18nSnackService,
    private prebookedServicesService: PrebookedServicesService,
    private voucherService: VoucherService,
    private currencyEuroPipe: CurrencyEuroPipe,
    private matDialog: MatDialog,
    private enzoDialogService: EnzoDialogService,
    private productAnalyticsService: ProductAnalyticsService,
  ) {
    this.buyerAuctionService.auctionBuyerClosed$.subscribe(uuid => this.closeBiddingDialogs(uuid));
  }

  public isBiddingAgentSet(auction: IBuyerAuctionView): boolean {
    return !!auction.biddingAgentValue && auction.biddingAgentValue > 0;
  }

  public isBidHighEnough(auction: IBuyerAuctionView, currentBidValue: number): boolean {
    if (currentBidValue === null) {
      return false;
    } else {
      return currentBidValue >= this.getMinimalBidThatIsHigher(auction);
    }
  }

  public getMinimalBidThatIsHigher(auction: IBuyerAuctionView): number {
    // TODO: What if current highest bid value is not defined?
    return auction.currentHighestBidValue + this.getMinimalBidIncrement(auction);
  }

  public getHighestPossibleBid(auction: IBuyerAuctionView): number {
    return auction?.bidMaxValueForBlocking ? auction.bidMaxValueForBlocking - 1 : null;
  }

  public getMinimalBidIncrement(auction: IBuyerAuctionView): number {
    return minimalBidIncrement(auction?.currentHighestBidValue || 0);
  }

  public getBidIncrements(auction: IBuyerAuctionView): [number, number?] {
    const minimalIncrement = this.getMinimalBidIncrement(auction);
    if (auction.isTest) {
      return [minimalIncrement];
    }

    const safeMinAsk = auction.minimumRequiredAsk || 0;
    const safeCurrentHighestBidValue = auction.currentHighestBidValue || 0;
    const gap: number = (safeMinAsk - safeCurrentHighestBidValue) / BID_INCREMENT_WITH_GAP_DIVIDER;

    if (gap <= minimalIncrement) {
      return [minimalIncrement];
    }

    const roundingValue =
      gap < BID_INCREMENT_WITH_GAP_ROUNDING_THRESHOLD ? BID_INCREMENT_WITH_GAP_BELOW_ROUNDING_THRESHOLD : BID_INCREMENT_WITH_GAP_ABOVE_ROUNDING_THRESHOLD;
    const roundedGap = Math.round(gap / roundingValue) * roundingValue;

    if (roundedGap === minimalIncrement) {
      return [minimalIncrement];
    }

    if (roundedGap < BID_INCREMENT_WITH_GAP_MAX) {
      return [minimalIncrement, roundedGap];
    }

    return [minimalIncrement, BID_INCREMENT_WITH_GAP_MAX];
  }

  private shouldShowBidTooHighWarning(auction: IBuyerAuctionView, currentBidValue: number): boolean {
    return !!auction?.bidMaxValueForWarning && !!currentBidValue && currentBidValue >= auction.bidMaxValueForWarning;
  }

  /*
   * returns an observable that
   * - emits true on success or
   * - either completes without emission or emits false if anything goes wrong
   */
  public directlyBidOnAuction(
    buyerUser: IBuyerUser,
    auction: IBuyerAuctionView,
    prebookedServices: IPrebookedService[],
    bidValue: number,
    isListViewBid?: boolean,
    recommendationId?: string,
  ): Observable<IAuctionBid | null> {
    if (bidValue === null || !this.isBidHighEnough(auction, bidValue)) {
      return of(null);
    }

    const formattedBidValue: string = this.currencyEuroPipe.transform(bidValue);
    return this.assertBuyerCanBid(buyerUser).pipe(
      filter(isConfirmed => isConfirmed),
      switchMap(() => {
        if (this.shouldShowBidTooHighWarning(auction, bidValue)) {
          return this.showBiddingConfirmationDialog(auction, prebookedServices, bidValue, 'dialog.buyer.confirm-bid-too-high', formattedBidValue);
        }
        if (!this.buyerAuctionService.isHotBidPhaseActive(auction)) {
          return this.showBiddingConfirmationDialog(auction, prebookedServices, bidValue, 'dialog.buyer.confirm-bid', formattedBidValue);
        }
        return of(undefined);
      }),
      switchMap((result?: IBiddingConfirmationResult) => {
        return this.bidUpdatePrebookingsVoucherAndRefresh(
          auction.uuid,
          this.cosBuyerClientService.bidOnAuction(auction.uuid, bidValue, isListViewBid, recommendationId),
          this.prebookedServicesService.persistPrebookedServices(auction.uuid, result?.updatedPrebookedServices),
          this.voucherService.persistVoucherAssignment(auction.uuid),
        );
      }),
      map(biddingResult => {
        this.i18nSnackService.openWithOk('auction.bidding.amount-x-was-offered', null, { amountOffered: formattedBidValue });
        return biddingResult;
      }),
      catchError(errorResponse => {
        this.handleAuctionBiddingError(errorResponse, auction.uuid);
        return of(null);
      }),
    );
  }

  /*
   * returns an observable that
   * - emits true on success or
   * - either completes without emission or emits false if anything goes wrong
   */
  public setBiddingAgent(buyerUser: IBuyerUser, auction: IBuyerAuctionView, prebookedServices: IPrebookedService[], bidValue: number): Observable<boolean> {
    const formattedBidValue: string = this.currencyEuroPipe.transform(bidValue);

    return this.assertBuyerCanBid(buyerUser).pipe(
      filter(isConfirmed => isConfirmed),
      switchMap(() => {
        if (this.shouldShowBidTooHighWarning(auction, bidValue)) {
          return this.showBiddingConfirmationDialog(auction, prebookedServices, bidValue, 'dialog.buyer.confirm-bidding-agent-too-high', formattedBidValue);
        }
        if (!this.buyerAuctionService.isHotBidPhaseActive(auction)) {
          return this.showBiddingConfirmationDialog(auction, prebookedServices, bidValue, 'dialog.buyer.confirm-bidding-agent', formattedBidValue);
        }
        return of(undefined); // means: no prebooking update is needed (only the bidding confirmation dialog might return prebooking updates)
      }),
      switchMap((result?: IBiddingConfirmationResult) => {
        return this.bidUpdatePrebookingsVoucherAndRefresh(
          auction.uuid,
          this.cosBuyerClientService.placeBiddingAgent(auction.uuid, bidValue),
          this.prebookedServicesService.persistPrebookedServices(auction.uuid, result?.updatedPrebookedServices),
          this.voucherService.persistVoucherAssignment(auction.uuid),
        );
      }),
      map(() => {
        this.i18nSnackService.openWithOk('auction.bidding.bidding-agent-for-amount-x-set', null, { amountOffered: formattedBidValue });
        return true;
      }),
      catchError(errorResponse => {
        this.handleAuctionBiddingError(errorResponse, auction.uuid);
        return of(false);
      }),
    );
  }

  /*
   * returns an observable that
   * - emits true on success or
   * - either completes without emission or emits false if anything goes wrong
   */
  public instantlyAddToBid(
    buyerUser: IBuyerUser,
    auction: IBuyerAuctionView,
    prebookedServices: IPrebookedService[],
    add: number,
    isListViewBid?: boolean,
    recommendationId?: string,
  ): Observable<IAuctionBid | null> {
    const bidValue = auction.currentHighestBidValue + add;
    const formattedBidValue: string = this.currencyEuroPipe.transform(bidValue);
    const formattedBidAddValue: string = this.currencyEuroPipe.transform(add);

    return this.assertBuyerCanBid(buyerUser).pipe(
      filter(isConfirmed => isConfirmed),
      switchMap(() => {
        if (!this.buyerAuctionService.isHotBidPhaseActive(auction)) {
          return this.showBiddingConfirmationDialog(
            auction,
            prebookedServices,
            bidValue,
            'dialog.buyer.confirm-instant-bid',
            formattedBidValue,
            formattedBidAddValue,
          );
        }
        return of(undefined);
      }),
      switchMap((result?: IBiddingConfirmationResult) => {
        return this.bidUpdatePrebookingsVoucherAndRefresh(
          auction.uuid,
          this.cosBuyerClientService.bidOnAuction(auction.uuid, bidValue, isListViewBid, recommendationId),
          this.prebookedServicesService.persistPrebookedServices(auction.uuid, result?.updatedPrebookedServices),
          this.voucherService.persistVoucherAssignment(auction.uuid),
        );
      }),
      map(biddingResult => {
        this.i18nSnackService.openWithOk('auction.bidding.amount-x-was-offered', null, {
          amountOffered: `+${formattedBidAddValue} (${formattedBidValue})`,
        });
        return biddingResult;
      }),
      catchError(errorResponse => {
        this.handleAuctionBiddingError(errorResponse, auction.uuid);
        return of(null);
      }),
    );
  }

  private bidUpdatePrebookingsVoucherAndRefresh(
    auctionUuid: string,
    doBidding$: Observable<IAuctionBid | null>,
    doPrebookingUpdate$: Observable<void>,
    doVoucherAssignmentUpdate$: Observable<void>,
  ): Observable<IAuctionBid | null> {
    return forkJoin([doBidding$, doPrebookingUpdate$, doVoucherAssignmentUpdate$]).pipe(
      map(([biddingResult]) => {
        // Refresh auction data after bidding. Update prebooked services data from auction and
        // get the updated value of didIBidAtLeastOnce (could be added to the bidding-data endpoint)
        this.buyerAuctionService.refreshBuyerAuction(auctionUuid);
        return biddingResult; // always return the result of the bidding action, no matter if prebooked services were updated along the way
      }),
    );
  }

  /**
   * If the confirmation is rejected, the returned Observable does not emit anything and completes.
   * If the confirmation is acknowledged, it emits
   *  - an Observable<void> to execute in parallel with the actual bidding action (to update pre-booked services).
   *  - or undefined if only the bidding action shall be performed.
   */
  private showBiddingConfirmationDialog(
    auction: IBuyerAuctionView,
    prebookedServices: IPrebookedService[],
    bidValue: number,
    contentKey: string,
    formattedBidValue: string,
    formattedBidAddValue?: string,
  ): Observable<IBiddingConfirmationResult> {
    return this.openBiddingConfirmationDialog(auction, prebookedServices, bidValue, contentKey, formattedBidValue, formattedBidAddValue).pipe(
      filter(biddingConfirmationResult => biddingConfirmationResult?.isConfirmed), // do not emit if false (just complete without emission)
      map(biddingConfirmationResult => biddingConfirmationResult), // might be undefined
    );
  }

  public openBiddingConfirmationDialog(
    auction: IBuyerAuctionView,
    prebookedServices: IPrebookedService[],
    bidValue: number,
    contentKey: string,
    formattedBidValue: string,
    formattedBidAddValue?: string,
  ): Observable<IBiddingConfirmationResult> {
    if (this.isBiddingDialogOpened) {
      return of(undefined);
    }

    this.isBiddingDialogOpened = true;

    return this.matDialog
      .open<BiddingConfirmationDialogComponent, IBiddingConfirmationData, IBiddingConfirmationResult>(BiddingConfirmationDialogComponent, {
        id: EDialogId.BIDDING_CONFIRMATION,
        disableClose: true,
        panelClass: 'bidding-confirmation-dialog-container', // for sizing (the width shall not jump when the terms acceptance checkbox appears/disappears)
        data: {
          auction,
          prebookedServices,
          bidValue,
          translationBaseKey: contentKey,
          translationParams: {
            formattedBidValue: `<b>${formattedBidValue}</b>`,
            formattedBidAddValue,
            messageTemplate: '<div class="warning">{importantLastSentence}</div><br/>{mainMessage}',
          },
        },
      })
      .afterClosed()
      .pipe(tap(() => (this.isBiddingDialogOpened = false)));
  }

  public removeBiddingAgent(auctionUuid: string): void {
    this.cosBuyerClientService.removeBiddingAgent(auctionUuid).subscribe(() => {
      this.buyerAuctionService.refreshBiddingInfos([auctionUuid]);
    });
  }

  public shouldDisplayRemainingBids(auction: IAuction): boolean {
    const minAsk: number = auction.minimumRequiredAsk;
    const remainingBids: number = this.getRemainingBids(auction);

    // if we do not know the minAsk value, we cannot calculate the remaining number of bids
    if (!minAsk) {
      return false;
    }

    // If the car is rather cheap (<= AUCTION_IS_EXPENSIVE_THRESHOLD EUR), 3 remaining bids should be shown max.
    // if the car is above that threshold, 5 remaining bids should be shown max
    return (minAsk <= AUCTION_IS_EXPENSIVE_THRESHOLD && remainingBids <= 3) || (minAsk > AUCTION_IS_EXPENSIVE_THRESHOLD && remainingBids <= 5);
  }

  public getRemainingBids({ minimumRequiredAsk, currentHighestBidValue }): number {
    return remainingBids(currentHighestBidValue, minimumRequiredAsk);
  }

  private handleAuctionBiddingError(errorResponse, auctionUuid: string): void {
    switch (errorResponse.status) {
      // Not allowed to bid on auction anymore
      case 403:
        I18nErrorDialogComponent.show(this.matDialog, 'dialog.buyer.bidding-no-longer-allowed', '', '400px');
        this.buyerAuctionService.refreshBiddingInfos([auctionUuid]);
        break;

      // Bid prohibited
      case 419:
        this.i18nSnackService.open('auction.bidding.not-allowed');
        this.buyerAuctionService.refreshBiddingInfos([auctionUuid]);
        break;

      // Outbid in the meantime
      case 428:
        this.i18nSnackService.open('auction.bidding.higher-offer-received', null, { duration: 5000 });
        this.buyerAuctionService.refreshBiddingInfos([auctionUuid]);
        break;

      default:
        I18nErrorDialogComponent.show(this.matDialog, 'dialog.general.error-please-try-again', '', '300px');
        this.buyerAuctionService.refreshBiddingInfos([auctionUuid]);
        break;
    }
  }

  public closeBiddingDialogs(uuid: string) {
    const confirmDialogRef: DialogRefOrMatDialogRef<BiddingConfirmationDialogComponent> = this.enzoDialogService.getDialogById(EDialogId.BIDDING_CONFIRMATION);
    if (confirmDialogRef && confirmDialogRef.componentInstance.auction?.uuid === uuid) {
      confirmDialogRef.close(false);
    }

    const mobileDialogRef: DialogRefOrMatDialogRef<MobileBidDialogComponent> = this.enzoDialogService.getDialogById(EDialogId.MOBILE_BID);
    if (mobileDialogRef && mobileDialogRef.componentInstance.auction?.uuid === uuid) {
      mobileDialogRef.close();
    }
  }

  private assertBuyerCanBid(buyerUser: IBuyerUser): Observable<boolean> {
    return this.shouldOpenSelfRegisterModal().pipe(
      switchMap((should: boolean) => {
        if (should) {
          return this.openBuyerSelfRegisterModal().pipe(map(() => false));
        }
        if (buyerUser?.biddingIsProhibited) {
          return this.showProhibitedBidHint().pipe(map(() => false));
        }
        return of(true);
      }),
    );
  }

  private shouldOpenSelfRegisterModal(): Observable<boolean> {
    return this.accountDataSvc.getAccountData().pipe(map((account: IAccount) => account.isPreregisteredAccount));
  }

  private showProhibitedBidHint(): Observable<any> {
    return I18nInfoDialogComponent.show(this.matDialog, EDialogId.BIDDING_IS_PROHIBITED, '450px');
  }

  private openBuyerSelfRegisterModal(): Observable<any> {
    return this.matDialog
      .open<BuyerSelfRegisterModalComponent>(BuyerSelfRegisterModalComponent, {
        width: '450px',
        id: EDialogId.SELF_REGISTER_MODAL,
      })
      .afterClosed();
  }

  public isBidTooHigh(auction: IBuyerAuctionView, currentBidValue: number): boolean {
    return !!currentBidValue && !!this.getHighestPossibleBid(auction) && currentBidValue > this.getHighestPossibleBid(auction);
  }

  public trackIfBidTooHigh(auction: IBuyerAuctionView, bidValue: number, bidSource: BidProhibitedProperties['Bid source']): void {
    if (!this.isBidTooHigh(auction, bidValue)) {
      return;
    }

    if (
      this.lastTrackedBidProhibitedEvent?.auctionUuid !== auction.uuid ||
      this.lastTrackedBidProhibitedEvent?.bidValue !== bidValue ||
      this.lastTrackedBidProhibitedEvent?.bidSource !== bidSource
    ) {
      this.lastTrackedBidProhibitedEvent = { auctionUuid: auction.uuid, bidValue: bidValue, bidSource };
      this.productAnalyticsService.trackEvent('bidProhibited', {
        'Auction uuid': auction.uuid,
        'Bid value': bidValue,
        'Min ask price': auction.minimumRequiredAsk,
        'Bid prohibited reason': 'Bid too high',
        'Max bid value': auction.bidMaxValueForBlocking,
        'Bid source': bidSource,
      });
    }
  }
}
