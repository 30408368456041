import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { EAddressType, IAccountAddress } from '@caronsale/cos-models';

/**
 * Service used to share address state;
 * This is needed since multiple places in different branches of the same component tree are constantly changing addresses;
 * Shares its inner state in addresses$ and is changed with functions iterating over the current state;
 */
@Injectable({
  providedIn: 'root',
})
export class UserAddressStateService implements OnDestroy {
  public busy: boolean = false;

  private lastKnownAddresses: IAccountAddress[];
  private addressesSubject$: BehaviorSubject<IAccountAddress[]> = new BehaviorSubject(null);

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor() {
    this.addressesSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe((addresses: IAccountAddress[]) => (this.lastKnownAddresses = addresses));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public get addresses$(): Observable<IAccountAddress[]> {
    return this.addressesSubject$.pipe(
      map(addresses => addresses?.filter(address => !(address.addressTypes?.length === 1 && address.addressTypes.includes(EAddressType.OWNER)))),
    );
  }

  public get businessAddress$(): Observable<IAccountAddress> {
    return this.addressesSubject$.pipe(
      filter((addresses: IAccountAddress[]) => !!addresses?.length),
      map((addresses: IAccountAddress[]) => addresses.find(el => this.isAddressType(el, EAddressType.BUSINESS))),
    );
  }

  public get editableAddresses$(): Observable<IAccountAddress[]> {
    return this.addressesSubject$.pipe(
      filter((addresses: IAccountAddress[]) => !!addresses?.length),
      map((addresses: IAccountAddress[]) =>
        addresses.filter(
          address =>
            !(address.addressTypes?.length === 1 && address.addressTypes.includes(EAddressType.OWNER)) && !this.isAddressType(address, EAddressType.BUSINESS),
        ),
      ),
    );
  }

  public setAddresses(addresses: IAccountAddress[]): void {
    this.addressesSubject$.next(this.reorderAddresses(addresses));
  }

  // if no uuid, just removes the type from everywhere
  public changeAddressType(type: Exclude<EAddressType, EAddressType.OWNER>, uuid?: string): void {
    this.setAddresses(
      this.lastKnownAddresses.map((address: IAccountAddress) => {
        if (!!uuid && address.uuid === uuid) {
          return { ...address, addressTypes: [...address.addressTypes, type] } as IAccountAddress;
        }

        return { ...address, addressTypes: address.addressTypes.filter(t => t !== type) };
      }),
    );
  }

  public addEmptyAddress(): void {
    this.addressesSubject$.next([
      ...this.lastKnownAddresses,
      {
        uuid: null,
        addressLine: '',
        zipCode: '',
        city: '',
        countryCode: null,
      } as IAccountAddress,
    ]);
  }

  public removeAddressAtPosition(index: number): void {
    this.addressesSubject$.next(this.lastKnownAddresses.filter((_: IAccountAddress, i: number) => i !== index));
  }

  public removeAddressByUuid(uuid: string): void {
    this.addressesSubject$.next(this.lastKnownAddresses.filter((address: IAccountAddress) => address.uuid !== uuid));
  }

  public getAddressByUuid(uuid: string): IAccountAddress {
    return this.lastKnownAddresses?.find((address: IAccountAddress) => address.uuid === uuid);
  }

  public switchAddressAtPosition(newAddress: IAccountAddress, index: number): void {
    this.setAddresses(this.lastKnownAddresses.map((address: IAccountAddress, i: number) => (i === index ? newAddress : address)));
  }

  public updateAddress(newAddress: IAccountAddress): void {
    this.setAddresses(this.lastKnownAddresses.map((address: IAccountAddress) => (address.uuid === newAddress.uuid ? newAddress : address)));
  }

  private reorderAddresses(addresses: IAccountAddress[]): IAccountAddress[] {
    const bizAddress: IAccountAddress = addresses.find(address => address.addressTypes?.includes(EAddressType.BUSINESS));
    const empty: IAccountAddress[] = addresses.filter(address => !address.uuid);
    // reorder address list to have business address as the first element, reorder rest of list alphabetically by city and addressLine
    const ordered: IAccountAddress[] = addresses
      .filter(address => address.uuid && address.uuid !== bizAddress.uuid)
      .sort((a, b) => (a.city > b?.city || (a.city === b.city && a.addressLine > b.addressLine) ? 1 : -1));

    return [bizAddress, ...ordered, ...empty];
  }

  public isAddressType(address: IAccountAddress, type: EAddressType): boolean {
    return address?.addressTypes?.includes(type);
  }
}
