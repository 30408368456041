import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

export type SupportedBackgroundColor = 'orange' | 'grey' | 'purple' | 'green' | 'green-ultralight' | 'orange-ultralight' | 'red-ultralight' | 'none';

export type SupportedColor =
  | 'orange'
  | 'orange-dark'
  | 'grey-ultradark'
  | 'purple-dark'
  | 'green'
  | 'green-dark'
  | 'blackish'
  | 'red'
  | 'red-dark'
  | 'black'
  | 'purple';

@Component({
  selector: 'app-tag-with-icon',
  standalone: true,
  imports: [CommonModule, EnzoComponentsModule],
  template: `
    <div class="tag" [ngClass]="backgroundColor" testid="tag">
      <enzo-icon *ngIf="icon" [color]="color" [icon]="icon" size="small" testid="tag-icon"></enzo-icon>
      <enzo-text *ngIf="innerHTML" [kind]="kind" [color]="color" [innerHTML]="innerHTML"></enzo-text>
      <enzo-text *ngIf="!innerHTML && label" [kind]="kind" [color]="color"> {{ label }} </enzo-text>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tag-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagWithIconComponent {
  @Input()
  public backgroundColor: SupportedBackgroundColor = 'none';

  @Input()
  public color: SupportedColor = 'blackish';

  @Input()
  public label: string;

  @Input()
  public innerHTML: string;

  @Input()
  public icon: string;

  @Input()
  public kind: 'emphasis-label' | 'label' = 'emphasis-label';
}
