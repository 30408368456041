import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EAuctionDiscountReason, IAuctionDiscount, IBuyerAuctionView } from '@caronsale/cos-models';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TagWithIconComponent } from '@cos/partials/tag-with-icon/tag-with-icon.component';

type DiscountItem = {
  percentage: number;
  typeLabel: 'total' | 'partial';
};

@Component({
  selector: 'app-auction-fee-discount-tag',
  templateUrl: './auction-fee-discount.component.html',
  imports: [TranslateModule, CosPopoverModule, EnzoComponentsModule, TagWithIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AuctionFeeDiscountComponent {
  private productAnalyticsService = inject(ProductAnalyticsService);

  public auction = input.required<IBuyerAuctionView>();
  public isStrategicSellerFlagOn = toSignal(this.productAnalyticsService.isOn('mpw-strategic-seller-fee'));

  public discountItem: Signal<DiscountItem | undefined> = computed(() => {
    if (!this.isStrategicSellerFlagOn()) {
      return this.selectFirstDiscountItem();
    }

    if (this.auction().isMinAskReached || !this.auction().appliedDiscounts?.length) {
      return undefined;
    }

    const discount: IAuctionDiscount =
      this.auction().appliedDiscounts.find(({ reason }) => reason === EAuctionDiscountReason.REGULAR_BUYER) || this.auction().appliedDiscounts[0];

    return {
      percentage: discount.value * 100,
      typeLabel: discount.value === 1 ? 'total' : 'partial',
    };
  });

  private selectFirstDiscountItem(): DiscountItem | undefined {
    const discount: number = this.auction().appliedDiscounts?.[0]?.value;
    return discount
      ? {
          percentage: discount * 100,
          typeLabel: discount === 1 ? 'total' : 'partial',
        }
      : undefined;
  }
}
