@if (techStateDisplay().length || damagesDisplay().length) {
  <div class="card">
    <enzo-headline level="5" color="blackish">{{ 'vehicle.tech-state' | translate }}</enzo-headline>

    @if (coveredTechState().length) {
      <div class="cos-garantee-header">
        <div>
          <enzo-icon icon="cos-check-plus" color="yellow" />
          <enzo-headline level="5" color="yellow">Guarantee <span>COS</span> Check<span>Plus</span></enzo-headline>
          <enzo-button [label]="('general.more-info' | translate) + '?'" size="small" (enzoClick)="showLearnMorePopover($event)" />
        </div>

        @if (isFreeOfCharge()) {
          <enzo-text kind="emphasis-label" color="white">{{ 'vehicle.cos-warranty-5k-back' | translate }}</enzo-text>
          <enzo-badge [label]="'general.completely-free' | translate" color="yellow-highlight" />
        }
      </div>

      <div class="highlighted-section">
        @if (!isFreeOfCharge()) {
          <enzo-text kind="emphasis-label" [innerHTML]="'vehicle.cos-warranty-subtitle.message' | translate: { messageTemplate: purchasesUrlTemplate }" />
        }
        @for (state of coveredTechState(); track state.part) {
          <div class="card-line covered">
            <enzo-text color="blackish">{{ state.part | i18nVehiclePart | translate }}</enzo-text>
            <enzo-ghost-button variant="highlight" size="small" icon="info-outline" iconOnly (enzoClick)="showGuaranteePopover($event, state.description)" />
            <enzo-text align="right">
              <enzo-icon [icon]="state.icon" [color]="state.iconColor" size="small" />
              <span [innerHTML]="state.description | i18nVehicleTechStateDescription | safeHtml"></span>
            </enzo-text>
          </div>
        }
      </div>
    }

    @for (state of uncoveredTechState(); track state.part) {
      @if ($last && hasOtherState()) {
        <ng-container *ngTemplateOutlet="relatedDamages" />
      }

      <div class="card-line">
        <enzo-text color="blackish">{{ state.part | i18nVehiclePart | translate }}</enzo-text>
        <enzo-text align="right">
          <enzo-icon [icon]="state.icon" [color]="state.iconColor" size="small" />
          <span [innerHTML]="state.description | i18nVehicleTechStateDescription | safeHtml"></span>
        </enzo-text>
      </div>

      @if ($last && !hasOtherState()) {
        <ng-container *ngTemplateOutlet="relatedDamages" />
      }
    }

    <ng-template #relatedDamages>
      @for (damage of damagesDisplay(); track damage.part) {
        <div class="card-line">
          <enzo-text color="blackish">{{ damage.part | i18nVehiclePart | translate }}</enzo-text>
          <enzo-text align="right">
            <enzo-icon [icon]="damage.icon" [color]="damage.iconColor" size="small" />
            @for (damageType of damage.damageTypes; track damageType; let last = $last) {
              {{ damageType | i18nVehicleDamageType | translate }}{{ !last ? ',' : '' }}
            }
          </enzo-text>
        </div>
      }
    </ng-template>
  </div>
}

@if (auction().associatedVehicle.urlsToMotorRecordings) {
  <div class="card">
    <enzo-headline level="5" color="blackish">{{ 'vehicle.motor-video' | translate }}</enzo-headline>

    <app-video-gallery [videoUrls]="auction().associatedVehicle.urlsToMotorRecordings" [setAutoPlay]="false" />
  </div>
}

@if (auction().associatedVehicle.urlToMotorSound) {
  <div class="card">
    <enzo-headline level="5" color="blackish">{{ 'vehicle.motor-sound-label' | translate }}</enzo-headline>

    <audio controls [src]="auction().associatedVehicle.urlToMotorSound"></audio>
  </div>
}
