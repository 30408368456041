import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, catchError, tap } from 'rxjs';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { IBuyerUser, DEFAULT_AUCTION_DETAIL_VIEW_SECTION_ORDER, IAuctionDetailViewPreferences, GENERAL_USER_ROLES } from '@caronsale/cos-models';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';

@Injectable({ providedIn: 'root' })
export class AdvPreferencesService {
  private advPreferencesSubject = new BehaviorSubject<IAuctionDetailViewPreferences>({ sectionOrder: DEFAULT_AUCTION_DETAIL_VIEW_SECTION_ORDER });
  public advPreferences$: Observable<IAuctionDetailViewPreferences> = this.advPreferencesSubject.asObservable();

  public constructor(
    private cosCoreClient: CosCoreClient,
    private cosBuyerClient: CosBuyerClientService,
  ) {
    this.initAdvPreferences();
  }

  public initAdvPreferences(): void {
    if (!this.cosCoreClient.getLastAuthenticationResult().privileges.includes(GENERAL_USER_ROLES.BUYER)) {
      return this.setDefaultSectionOrder();
    }

    this.cosBuyerClient
      .getCurrentBuyerUser()
      .pipe(
        tap(userProfile => {
          const preferences = this.validateAdvPreferences(userProfile);
          this.advPreferencesSubject.next(preferences);
        }),
        catchError(() => {
          this.setDefaultSectionOrder();
          return of(null);
        }),
      )
      .subscribe();
  }

  private validateAdvPreferences(user: IBuyerUser): IAuctionDetailViewPreferences {
    const preferences = user.auctionDetailViewPreferences ?? {};
    if (!preferences.sectionOrder) {
      preferences.sectionOrder = DEFAULT_AUCTION_DETAIL_VIEW_SECTION_ORDER;
    }

    DEFAULT_AUCTION_DETAIL_VIEW_SECTION_ORDER.forEach(section => {
      if (!preferences.sectionOrder.includes(section)) {
        preferences.sectionOrder.push(section);
      }
    });

    return preferences;
  }

  private setDefaultSectionOrder(): void {
    this.advPreferencesSubject.next({ ...this.advPreferencesSubject.getValue(), sectionOrder: DEFAULT_AUCTION_DETAIL_VIEW_SECTION_ORDER });
  }

  public updateAdvPreferences(preferences: IAuctionDetailViewPreferences): void {
    this.advPreferencesSubject.next(preferences);
  }
}
