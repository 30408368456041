import { ChangeDetectionStrategy, Component, effect, input, signal } from '@angular/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@Component({
  standalone: true,
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoComponentsModule],
})
export class VideoGalleryComponent {
  public videoUrls = input.required<string[]>();
  public setAutoPlay = input<boolean>(true);

  public selectedIndex = signal(0);

  public constructor() {
    effect(
      () => {
        if (this.videoUrls()?.length) {
          this.selectedIndex.set(0);
        }
      },
      { allowSignalWrites: true },
    );
  }

  public increaseSelectedIndex(): void {
    this.selectedIndex.update(currentIndex => (currentIndex + 1) % this.videoUrls().length);
  }

  public decreaseSelectedIndex(): void {
    this.selectedIndex.update(currentIndex => (currentIndex - 1 + this.videoUrls().length) % this.videoUrls().length);
  }
}
