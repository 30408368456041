<ng-container [ngSwitch]="transportationAvailability">
  <ng-container *ngSwitchCase="ETransportationAvailability.ENFORCED_PRICE_NOT_REQUESTED_YET">
    <ng-container *ngTemplateOutlet="transportEnforced"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ETransportationAvailability.ENFORCED_PRICE_IS_REQUESTED">
    <ng-container *ngTemplateOutlet="transportEnforced"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ETransportationAvailability.ENFORCED_PRICE_IS_KNOWN">
    <ng-container *ngTemplateOutlet="transportEnforced"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="ETransportationAvailability.AVAILABLE_PRICE_NOT_REQUESTED_YET">
    <enzo-button
      [loading]="transportationRequestLoading"
      testid="request-transport-price-btn"
      stretch
      [label]="'action.request-transport-price' | translate"
      (enzoClick)="requestTransportPrice($event, auction)"
      icon="transport"
      iconPosition="leading">
    </enzo-button>
    <!-- todo: determine if a discount would be offered
      <small class="text-warn"> {{'transport.24h-discount-message' | translate}} </small-->
  </ng-container>

  <enzo-text
    *ngSwitchCase="ETransportationAvailability.AVAILABLE_PRICE_IS_REQUESTED"
    (click)="showTransportationRequestedInfo($event)"
    color="yellow"
    kind="emphasis-label"
    icon="auction-time"
    iconSize="small"
    class="requested"
    testid="price-is-requested">
    {{ 'transport.price-is-requested' | translate }}
  </enzo-text>

  <ng-container *ngIf="showIfNotAvailable">
    <div
      *ngSwitchCase="ETransportationAvailability.BOOKING_NOT_POSSIBLE_ANY_MORE"
      [matTooltip]="'transport.not-available.day-after-released-booking-hint' | translate">
      <ng-container *ngTemplateOutlet="transportNotAvailable"></ng-container>
    </div>
    <div *ngSwitchCase="ETransportationAvailability.NOT_ALLOWED_FOR_REGION" [matTooltip]="'transport.not-available.not-allowed-for-region' | translate">
      <ng-container *ngTemplateOutlet="transportNotAvailable"></ng-container>
    </div>
    <div *ngSwitchCase="ETransportationAvailability.NOT_AVAILABLE">
      <ng-container *ngTemplateOutlet="transportNotAvailable"></ng-container>
    </div>
    <div *ngSwitchCase="ETransportationAvailability.DISABLED_MANUALLY" [matTooltip]="'transport.not-available.disabled-manually' | translate">
      <ng-container *ngTemplateOutlet="transportNotAvailable"></ng-container>
    </div>
    <div *ngSwitchCase="ETransportationAvailability.JOB_REJECTED">
      <ng-container *ngTemplateOutlet="transportNotAvailable"></ng-container>
    </div>
  </ng-container>

  <div testid="booked-or-prebooked" *ngSwitchCase="ETransportationAvailability.BOOKED_OR_PREBOOKED">
    <ng-container *ngTemplateOutlet="isBookedLabel"></ng-container>
    <enzo-button
      class="edit-delivery-address-button"
      testid="edit-booking-delivery-address-btn"
      stretch
      icon="transport"
      iconPosition="leading"
      [label]="'transport.edit-delivery-address' | translate"
      [loading]="transportationRequestLoading"
      (enzoClick)="editDeliveryAddress()">
    </enzo-button>
  </div>

  <div testid="booked-or-prebooked-not-possible-anymore" *ngSwitchCase="ETransportationAvailability.BOOKED_OR_PREBOOKED_BOOKING_NOT_POSSIBLE_ANY_MORE">
    <ng-container *ngTemplateOutlet="isBookedLabel"></ng-container>
  </div>

  <div
    *ngSwitchCase="ETransportationAvailability.AVAILABLE_PRICE_IS_KNOWN"
    [ngClass]="{ 'no-discount': !isDiscountAvailable }"
    class="booking-wrapper"
    testid="available-price-is-known">
    <enzo-button
      [loading]="transportationRequestLoading"
      (enzoClick)="confirmTransportation(auction)"
      icon="transport"
      iconPosition="leading"
      testid="confirm-transportation-btn"
      label="{{ 'transport.book-transport-for' | translate }} {{
        isDiscountAvailable ? (auction.transportationTask?.discountedNetPrice | currency: 'EUR') : (auction.transportationTask?.netPrice | currency: 'EUR')
      }}"
      stretch>
    </enzo-button>

    <div class="booking-info">
      <enzo-text *ngIf="shouldDisplayDiscountCountdown()" kind="caption">
        {{ 'transport.discount-remaining-time' | translate: { remainingTime: discountAvailableUntil | timeSpan } }}
      </enzo-text>
      <enzo-text *ngIf="isDiscountAvailable" kind="caption" align="right" class="net-price-with-discount">
        <span class="net-price">{{ auction.transportationTask?.netPrice | currency: 'EUR' }}</span>
        <span class="discounted-percentage">↓{{ discount | i18nPercent: '1.0-1' }}</span>
      </enzo-text>
      <enzo-text kind="caption" align="right" class="vat"> ({{ 'taxation.vat-exclusive' | translate }}) </enzo-text>
    </div>
  </div>
</ng-container>

<ng-template #transportNotAvailable>
  <enzo-button
    stretch
    disabled
    kind="secondary"
    [label]="'transport.not-available.transport-not-available' | translate"
    testid="transport-not-available"
    icon="transport"
    iconPosition="leading">
  </enzo-button>
</ng-template>

<ng-template #transportEnforced>
  <enzo-text *ngIf="!isVehicleReauctioned()" icon="transport" iconSize="small" color="green" kind="emphasis-label" class="transport-enforced">
    {{ (auction.isCrossBorderNetSale ? 'transport.mandatory-transport-booked-net-export' : 'transport.mandatory-transport-booked') | translate }}
  </enzo-text>
</ng-template>

<ng-template #isBookedLabel>
  <enzo-text *ngIf="showBookedLabel" kind="emphasis-label" icon="transport" iconSize="small" color="green" class="booked">
    {{ 'transport.is-booked-for' | translate: { price: auction.transportationTask?.netPrice | currency: 'EUR' } }}
  </enzo-text>
</ng-template>
