import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { EReportType } from '../general-info/vehicle-info';

@Component({
  selector: 'app-service-badge',
  templateUrl: './service-badge.component.html',
  styleUrls: ['./service-badge.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceBadgeComponent {
  public service = input.required<'battery' | 'cos-check' | 'cos-check-plus' | 'guarantee' | EReportType>();
  public clickable = input<boolean>(true);
}
