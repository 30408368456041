<app-expandable-panel [expandedState]="true" testid="panel">
  <div panelTitle class="panel-title">
    <enzo-icon [icon]="'steering-wheel'"></enzo-icon>
    <enzo-headline level="4" color="blackish">{{ 'general.vehicle-equipment' | translate }}</enzo-headline>
  </div>

  <div panelContent>
    <enzo-text *ngIf="equipmentNotAvailableForDesiredLanguage" kind="caption" icon="info" iconSize="small">
      {{ 'vehicle.equipment-not-available-for-desired-language' | translate }}
    </enzo-text>
    <div class="cards-container" [style.height.px]="containerHeight">
      <div *ngFor="let group of equipmentGroups | keyvalue: asIsOrder" class="card">
        <enzo-headline level="5">{{ group.value.translationKey | translate }}</enzo-headline>
        <ul>
          <li *ngFor="let equipment of group.value.equipments">
            @if (equipment.type === EVehicleEquipmentType.SPECIAL) {
              <enzo-icon size="small" icon="recommended" color="yellow-dark" [matTooltip]="'general.special-equipment' | translate" />
            } @else {
              <enzo-icon size="small" icon="dot" />
            }
            <enzo-text overflow="ellipsis">{{ equipment.description }}</enzo-text>
          </li>
        </ul>
      </div>

      <div *ngIf="otherGroup.length" class="card" [class.full-width]="alternativeLayout">
        @if (alternativeLayout) {
          <div class="code-header">
            <enzo-headline level="5">{{ 'general.code' | translate }}</enzo-headline>
            <enzo-headline level="5">{{ 'general.description' | translate }}</enzo-headline>
            <enzo-text kind="emphasis-label" icon="recommended" iconColor="yellow-dark" iconSize="small" iconSpacing="2">
              {{ 'general.special-equipment' | translate }}
            </enzo-text>
          </div>
        } @else {
          <enzo-headline level="5">{{ 'general.other-category' | translate }}</enzo-headline>
        }
        <ul>
          <li *ngFor="let equipment of otherGroup">
            @if (equipment.type === EVehicleEquipmentType.SPECIAL) {
              <enzo-icon size="small" icon="recommended" color="yellow-dark" [matTooltip]="'general.special-equipment' | translate" />
            } @else {
              <enzo-icon size="small" icon="dot" />
            }
            @if (alternativeLayout) {
              <enzo-text kind="body-bold">{{ equipment.externalId }}</enzo-text>
            }
            <enzo-text overflow="ellipsis">{{ equipment.description }}</enzo-text>
          </li>
        </ul>
      </div>
    </div>

    @if (displayDataDisclaimerText) {
      <enzo-text kind="caption" class="data-disclaimer">* {{ 'vehicle.data-disclaimer-text' | translate }}</enzo-text>
    }
  </div>
</app-expandable-panel>
