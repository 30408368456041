<swiper-container init="false" appSwiper #swiper [config]="swiperConfig">
  @for (thumbnail of thumbnails(); track $index) {
    <swiper-slide lazy="true" [style.width.px]="thumbnailWidth" [class.selected]="$index === selectedIndex()" (click)="selectedIndex.set($index)">
      <img [ngSrc]="thumbnail.urlRef" fill ngSrcset="200w" />
    </swiper-slide>
  }
</swiper-container>

@if (displayNavigation()) {
  <enzo-icon color="white" icon="arrow-left" (click)="slidePrevious()" />
  <enzo-icon color="white" icon="arrow-right" (click)="slideNext()" />
}
